import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdDataUsage,
  MdOutlineShoppingCart,
  MdOutlineAccountBalanceWallet,
  MdPayment,
  MdPending
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Proxys from "views/admin/proxy";
import Users from "views/admin/user";
import UserActivities from "views/admin/userActivity";
import Invoices from "views/admin/invoice";
import PendingInvoices from "views/admin/pending-invoice";
import PaymentHistory from "views/admin/payment-history";
import Accounts from "views/admin/account";

// import SignIn from "views/auth/signIn/index.jsx";
// import SignUp from "views/auth/signUp/index.jsx";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
    isAdmin: false,
  },
  {
    name: "User Management",
    layout: "/admin",
    path: "/users",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Users,
    secondary: true,
    isAdmin: true,
  },
  {
    name: "Auction Settings",
    layout: "/admin",
    path: "/account",
    icon: (
      <Icon
        as={MdOutlineAccountBalanceWallet}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Accounts,
    isAdmin: true,
  },
  {
    name: "Bid History",
    layout: "/admin",
    icon: <Icon as={MdDataUsage} width="20px" height="20px" color="inherit" />,
    path: "/usr-activities",
    component: UserActivities,
    isAdmin: false,
  },
  // {
  //   name: "Proxy",
  //   layout: "/admin",
  //   path: "/proxy",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   component: Proxys,
  // },
  {
    name: "Invoices & Payments",
    layout: "/admin",
    path: "/invoice",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Invoices,
    isAdmin: false,
  },
  {
    name: "Approval Queue",
    layout: "/admin",
    path: "/pending-invoice",
    icon: (
      <Icon
        as={MdPending}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: PendingInvoices,
    isAdmin: false,
  },
  {
    name: "Transaction History",
    layout: "/admin",
    path: "/payment-history",
    icon: (
      <Icon
        as={MdPayment}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: PaymentHistory,
    isAdmin: false,
  },
];

export default routes;
