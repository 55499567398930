import axios from "./index";

class UserActivityApi {
  static getAll = (data) => {
    return axios.get(`${base}/all`);
  };

  static createOne = (data) => {
    return axios.post(`${base}/create`, data);
  };

  static delete = (data) => {
    return axios.post(`${base}/delete`, data);
  };

  static update = (data) => {
    return axios.post(`${base}/update`, data);
  };

  static getLots = () => {
    return axios.get(`${base}/lots`);
  };

  static getPendingInvoice = () => {
    return axios.get(`${base}/getPendingInvoice`);
  };
}

let base = "admin/monitor";

export default UserActivityApi;
