import { Box, SimpleGrid } from "@chakra-ui/react";
import PaymentHistoryTable from "./components/paymentHistoryTable";
import React, { useEffect, useState } from "react";
import PaymentApi from "api/payment";

export default function PaymentHistory() {
  var user = localStorage.getItem("user");
  user = JSON.parse(user);

  const fetchPaymentHistory = () => [
    PaymentApi.getAll().then((res) => {
      setHistories(res.data);
    }),
  ];

  var columns = [
    {
      Header: "CREATED AT",
      accessor: "createdAt",
    },
    {
      Header: "COMPANY NAME",
      accessor: "user.company_name",
    },
    {
      Header: "EMAIL",
      accessor: "user.email",
    },
    {
      Header: "TYPE",
      accessor: "type",
    },
    {
      Header: "DESCRIPTION"
    },
    {
      Header: "AMOUNT",
      accessor: "amount",
    },
    {
      Header: "STATUS",
      accessor: "is_approved",
    },
  ];

  if (user && user.role == "user") {
    // delete columns[1];
  }

  const [histories, setHistories] = useState([]);

  useEffect(() => {
    fetchPaymentHistory();
  }, []);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <PaymentHistoryTable columnsData={columns} tableData={histories} />
      </SimpleGrid>
    </Box>
  );
}
