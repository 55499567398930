import { Box, SimpleGrid } from "@chakra-ui/react";
import PendingInvoiceTable from "./components/invoiceTable";
import React, { useEffect, useState } from "react";
import MonitorAPI from "api/userActivity";

export default function PendingInvoices() {
  var user = localStorage.getItem("user");
  user = JSON.parse(user);

  const fetchInvoices = () => {
    MonitorAPI.getPendingInvoice().then((res) => {
      setInvoices(res.data);
    });
  };

  var columns = [
    {
      Header: "CREATED AT",
      accessor: "createdAt",
    },
    {
      Header: "COMPANY NAME",
      accessor: "user.company_name",
    },
    {
      Header: "EMAIL",
      accessor: "user.email",
    },
    {
      Header: "AUCTION",
      accessor: "type",
    },
    {
      Header: "LOCATION",
      accessor: "location",
    },
    {
      Header: "CAR INFO",
      accessor: "name",
    },
    {
      Header: "VIN CODE",
      accessor: "vin_code",
    },
    {
      Header: "LOT ID",
      accessor: "lot_id",
    },
    {
      Header: "BID PRICE",
      accessor: "bid_price",
    },
    {
      Header: "FINAL PRICE",
      accessor: "invoice_price",
    },
    {
      Header: "STATUS",
      accessor: "isReserve",
    },
    {
      Header: "ACTION",
    },
  ];

  if (user && user.role == "user") {
    delete columns[2];
  }

  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    fetchInvoices();
  }, []);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <PendingInvoiceTable
          columnsData={columns}
          tableData={invoices}
          fetchInvoices={fetchInvoices}
        />
      </SimpleGrid>
    </Box>
  );
}
