import axios from "./index";

class InvoiceApi {
  static getAll = (data) => {
    return axios.get(`${base}/all`);
  };

  static createOne = (data) => {
    return axios.post(`${base}/create`, data);
  };

  static delete = (data) => {
    return axios.post(`${base}/delete`, data);
  };

  static update = (data) => {
    return axios.post(`${base}/update`, data);
  };

  static send = (data) => {
    return axios.post(`${base}/send`, data);
  };

  static download = (data) => {
    return axios.get(`${base}/download?invoiceID=${data}`, {
      responseType: "blob",
    });
  };
}

let base = "admin/invoice";

export default InvoiceApi;
