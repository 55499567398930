import axios from "./index";

class PaymentApi {
  static GetClientSecretKey = (data) => {
    return axios.post(`${base}/clientSecretKey`, data);
  };

  static AddPaymentHistory = (data) => {
    return axios.post(`${base}/add-payment-history`, data);
  };

  static getAll = (data) => {
    return axios.post(`${base}/get-payment-history`, data);
  };
}

let base = "admin/payment";

export default PaymentApi;
