import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Badge,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Select,
  Icon,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { DeleteIcon, PlusSquareIcon, EditIcon } from "@chakra-ui/icons";

import { toast } from "react-toastify";
import moment from "moment";
import UserApi from "api/user";
import AccountApi from "api/account";

// Custom components
import Card from "components/card/Card";
import { isEmpty, isEmail } from "utilities";

// Assets
export default function UserTable({
  columnsData,
  tableData,
  fetchUsers,
  hasCreateButton = true,
}) {
  const user = JSON.parse(localStorage.getItem("user"));
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const [selectedUser, setSelectedUser] = useState(0);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [copartAccount, setCopartAccount] = useState(0);
  const [iaaiAccount, setIAAIAccount] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [company, setCompany] = useState("");
  const [accounts, setAccounts] = useState({
    copart: [],
    iaai: []
  });

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const getAccounts = () => {
    AccountApi.getAll().then((res) => {
      var tmpAccounts = {
        copart: [],
        iaai: []
      }

      for (var account of res.data) {
        if (!tmpAccounts[account.type]) {
          tmpAccounts[account.type] = []
        }

        tmpAccounts[account.type].push(account)
      }

      setAccounts(tmpAccounts)
    })
  }

  const createUser = () => {
    if (
      isEmpty(email) ||
      isEmpty(role) ||
      isEmpty(name)
    ) {
      toast.error("Please input correct infomation.");
      return;
    }

    if (selectedUser == 0 && isEmpty(password)) {
      toast.error("Please input password.");
      return;
    }

    if (role == "owner" && isEmpty(company)) {
      toast.error("Please input company name.");
      return;
    }

    if (!isEmail(email)) {
      toast.error("Incorrect email.");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Confirm Password is not matched with password.");
      return;
    }

    var obj = { email, name, role, copartAccount, iaaiAccount, company }

    if (selectedUser == 0) {
      obj['password'] = password
    } else if (password != '') {
      obj['password'] = password
    }

    if (selectedUser != 0) {
      obj['id'] = selectedUser
      obj['isUpdate'] = true
    }

    UserApi.createOne(obj).then((res) => {
      if (res.status === 200) {
        toast.success("Create successful.");
        fetchUsers();
      } else {
        toast.error("Error is incorrect.");
      }

      onClose();
    });
  };

  const deleteUser = (id) => {
    UserApi.delete({ id }).then((res) => {
      if (res.status === 200) {
        toast.success("Deleted Success");
        fetchUsers();
      }
    });
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Users
        </Text>
        {hasCreateButton == true && (
          <Button size="md" colorScheme="blue" onClick={(e) => {
            setSelectedUser(0)
            setEmail("")
            setRole("")
            setCompany("")
            setPassword("")
            setConfirmPassword("")
            setCopartAccount(0)
            setIAAIAccount(0)
            getAccounts()
            onOpen()
          }}>
            <Icon w="20px" h="20px" me="5px" as={PlusSquareIcon} />
            Create User
          </Button>
        )}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  
                  if (cell.column.Header === "ROLE") {
                    var color = "blue"
                    var str = cell.value

                    if (cell.value == "admin") {
                      color = "red"
                    } else if (cell.value == "owner") {
                      color = "green"
                    }

                    if (cell.value == "owner") {
                      str = "Admin"
                    } else if (cell.value == "admin") {
                      str = "Super Admin"
                    }

                    data = (
                      <Badge
                        colorScheme={color}
                        variant="solid"
                      >
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {str}
                        </Text>
                      </Badge>
                    );
                  } else if (cell.column.Header === "LAST LOGIN DATE") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {!isEmpty(cell.value)
                          ? `${new Date(
                            cell.value * 1
                          ).toLocaleDateString()} ${new Date(
                            cell.value * 1
                          ).toLocaleTimeString()}`
                          : ""}
                      </Text>
                    );
                  } else if (cell.column.Header == "ACTION") {
                    data = (
                      <Flex>
                        <Button colorScheme="green" size="sm" mr="2" onClick={(e) => {
                          setSelectedUser(cell.row.original.id)
                          getAccounts()
                          setCompany(cell.row.original.company_name)
                          setName(cell.row.original.name)
                          setRole(cell.row.original.role)
                          setEmail(cell.row.original.email)
                          setCopartAccount(cell.row.original.copart_account)
                          setIAAIAccount(cell.row.original.iaai_account)
                          onOpen()
                        }}><Icon w="20px" h="20px" me="5px" as={EditIcon} />Edit</Button>
                        <Button
                          colorScheme="red"
                          size="sm"
                          onClick={() => deleteUser(row.values.id)}
                        >
                          <Icon w="20px" h="20px" me="5px" as={DeleteIcon} />
                          Delete
                        </Button>
                      </Flex>
                    );
                  } else {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  }

                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedUser == 0 ? "Create" : "Update"} User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input
                textColor={textColor}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="name">
              <FormLabel>Name</FormLabel>
              <Input
                textColor={textColor}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl id="role" mt="3">
              <FormLabel>Role</FormLabel>
              <Select
                placeholder="Select user role"
                onChange={(e) => setRole(e.target.value)}
                defaultValue={role}
              >
                {(user.role == "admin" && user.id == selectedUser) &&
                <option value="admin">Super Admin</option>}
                {(user.role == "admin" || (user.role == "owner" && user.id == selectedUser)) &&
                <option value="owner">Admin</option>}
                <option value="user">User</option>
              </Select>
            </FormControl>
            { role != 'user' &&
            <FormControl mt="3">
              <FormLabel>Company Name</FormLabel>
              <Input
                textColor={textColor}
                type="text"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </FormControl> }
            <FormControl id="role" mt="3">
              <FormLabel>Copart Account</FormLabel>
              <Select
                placeholder="Select Copart Account"
                onChange={(e) => setCopartAccount(e.target.value)}
                defaultValue={copartAccount}
              >
                {accounts.copart.map((e) => {
                  return <option key={e.id} value={e.id}>{e.username}</option>
                })}
              </Select>
            </FormControl>
            <FormControl id="role" mt="3">
              <FormLabel>IAAI Account</FormLabel>
              <Select
                placeholder="Select IAAI Account"
                onChange={(e) => setIAAIAccount(e.target.value)}
                // defaultValue={iaaiAccount}
              >
                {accounts.iaai.map((e) => {
                  return <option key={e.id} value={e.id}>{e.username}</option>
                })}
              </Select>
            </FormControl>
            <FormControl id="password" mt="3">
              <FormLabel>Password</FormLabel>
              <Input
                textColor={textColor}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <FormControl id="confirm_password" mt="3">
              <FormLabel>Confirm Password</FormLabel>
              <Input
                textColor={textColor}
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={createUser}>
              {selectedUser == 0 ? "Create" : "Update"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
