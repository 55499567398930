// Chakra imports
import { Box, Flex, Image } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/footer/FooterAuth";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import Video from "../../assets/img/auth/background.mp4";
import Logo from "../../assets/img/auth/logo.png";
// Custom components
// import { NavLink } from "react-router-dom";
// Assets
// import { FaChevronLeft } from "react-icons/fa";

function AuthIllustration(props) {
  const { children, illustrationBackground } = props;
  // Chakra color mode
  return (
    <Flex position="relative" h="max-content">
      <Box
        position="absolute"
        as="video"
        loop={true}
        autoPlay={true}
        muted={true}
        src={Video}
        objectFit="cover"
        w={{ base: "0%", xl: "100%" }}
        h="100%"
      />
      <Flex
        h="100vh"
        zIndex={2}
        w="100%"
        mx="auto"
        pt={{ sm: "50px", md: "0px" }}
        pb="0px"
        px="30px"
        justifyContent="start"
        direction="column"
      >
        <Image
          zIndex={1}
          src={Logo}
          w={{ base: "0px", xl: "300px" }}
          m={{ base: "0px", xl: "auto auto 20px auto" }}
        />
        {children}
        {/* <Box
          display={{ base: "none", md: "block" }}
          h='100%'
          minH='100vh'
          w={{ lg: "50vw", "2xl": "44vw" }}
          position='absolute'
          right='0px'>
          <Flex
            bg={`url(${illustrationBackground})`}
            justify='center'
            align='end'
            w='100%'
            h='100%'
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}></Flex>
        </Box> */}
        <Footer />
      </Flex>
      <FixedPlugin />
    </Flex>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
