import { Box, SimpleGrid } from "@chakra-ui/react";
import InvoiceTable from "./components/invoiceTable";
import React, { useEffect, useState } from "react";
import InvoiceApi from "api/invoice";

export default function Invoices() {
  var user = localStorage.getItem("user");
  user = JSON.parse(user);

  const fetchInvoices = () => {
    InvoiceApi.getAll().then((res) => {
      setInvoices(res.data);
    });
  };

  const columns = [
    {
      Header: "CREATED AT",
      accessor: "createdAt",
    },
    {
      Header: "COMPANY NAME",
      accessor: "user.company_name",
    },
    {
      Header: "EMAIL",
      accessor: "user.email",
    },
    {
      Header: "AUCTION",
      accessor: "monitor.type",
    },
    {
      Header: "LOCATION",
      accessor: "monitor.location",
    },
    {
      Header: "CAR INFO",
      accessor: "monitor.name",
    },
    {
      Header: "VIN CODE",
      accessor: "monitor.vin_code",
    },
    {
      Header: "STATUS",
      // accessor: "status",
    },
    {
      Header: "ACTION",
    },
  ];

  if (user && user.role == "user") {
    delete columns[2];
  }

  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    fetchInvoices();
  }, []);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <InvoiceTable
          columnsData={columns}
          tableData={invoices}
          fetchInvoices={fetchInvoices}
        />
      </SimpleGrid>
    </Box>
  );
}
