// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useClipboard,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  TabList,
  Input,
  Center,
  VStack,
  Box,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  NumberInput,
  NumberInputField,
  NumberIncrementStepper,
  NumberInputStepper,
  NumberDecrementStepper,
  Spinner
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../auth-context/auth.context";
import AuthApi from "../../api/auth";
import PaymentApi from "../../api/payment";
import {
  FaWallet,
  FaPaypal,
  FaCreditCard,
  FaEthereum,
  FaExclamationCircle,
} from "react-icons/fa";
import UserApi from "api/user";
import EthereumQRPlugin from "ethereum-qr-code";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { toast } from "react-toastify";

const stripePromise = loadStripe('pk_test_51NEd06DPuIi0aIq4HgUuu3uKSXQNWhV40pdfyB4ZRVhltNTc9x2KoNkKzUEJkCXkabq72mdLgthq5vVu79L2sBIJ00cClzMiWq')
var stripe
var elements

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function DepositAmountSelector(props) {
  const { setDepositAmount } = props
  const [sliderValue, setSliderValue] = useState(1)
  const labelStyles = {
    position: 'absolute',
    left: '-50px',
    top: '15px',
    color: 'white',
    width: '100px',
    textAlign: 'center'
  }
  const rulerStyles = {
    mt: "-5px",
    w: "3px",
    h: '10px',
    bg: 'var(--chakra-colors-blue-200)',
    position: 'relative'
  }

  const handleChange = (val) => {
    setSliderValue(val)
    setDepositAmount(val * 600)
  }

  return (
    <Box>
      <p style={{marginBottom: '100px'}}>
        The bidding limit is the maximum amount you can bid on. The size of the deposit is 10% of your maximum bid at the auction, but not less than $600.
      </p>
      <Slider
        aria-label='slider-ex-6'
        onChange={handleChange}
        defaultValue={1}
        value={sliderValue}
        min={1}
        max={10}
      >
        <SliderMark value={1} {...rulerStyles}>
          <div style={{... labelStyles, top: '-30px'}}>
            6K
          </div>
          <div style={labelStyles}>
            1 Vehicle
          </div>
        </SliderMark>
        <SliderMark value={2} {...rulerStyles}>
          <div style={{... labelStyles, top: '-30px'}}>
            12K
          </div>
        </SliderMark>
        <SliderMark value={3} {...rulerStyles}>
          <div style={{... labelStyles, top: '-30px'}}>
            18K
          </div>
        </SliderMark>
        <SliderMark value={4} {...rulerStyles}>
          <div style={{... labelStyles, top: '-30px'}}>
            24K
          </div>
        </SliderMark>
        <SliderMark value={5} {...rulerStyles}>
          <div style={{... labelStyles, top: '-30px'}}>
            30K
          </div>
          <div style={labelStyles}>
            5 Vehicles
          </div>
        </SliderMark>
        <SliderMark value={6} {...rulerStyles}>
          <div style={{... labelStyles, top: '-30px'}}>
            36K
          </div>
        </SliderMark>
        <SliderMark value={7} {...rulerStyles}>
          <div style={{... labelStyles, top: '-30px'}}>
            42K
          </div>
        </SliderMark>
        <SliderMark value={8} {...rulerStyles}>
          <div style={{... labelStyles, top: '-30px'}}>
            48K
          </div>
        </SliderMark>
        <SliderMark value={9} {...rulerStyles}>
          <div style={{... labelStyles, top: '-30px'}}>
            54K
          </div>
        </SliderMark>
        <SliderMark value={10} {...rulerStyles}>
          <div style={{... labelStyles, top: '-30px'}}>
            60K+
          </div>
          <div style={labelStyles}>
            10 Vehicles
          </div>
        </SliderMark>
        <SliderMark
          value={sliderValue > 10 ? 10 : sliderValue}
          textAlign='center'
          bg='blue.500'
          color='white'
          mt='-70px'
          ml='-12'
          px="1"
          w="100px"
        >
          {sliderValue > 10 ? 10 : sliderValue} Vehicles
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      <Text mt="70px" textAlign="center" fontSize='lg'>You can bid up to $6,000 and buy 1 car.</Text>
      <Flex mt="50px">
        <Flex w='100px' justifyContent="center" flexDirection="column">
          <Text mb="5px" textAlign="left" fontSize='md'>Your Bid Limit:</Text>
          <Text textAlign="left" fontSize='xl'>${numberWithCommas(sliderValue * 6000)}</Text>
        </Flex>
        <Flex w='calc(100% - 200px)' flexDirection="column" justifyContent="center">
          <Text mb="5px" textAlign="center" fontSize='md'>Number of cars purchased:</Text>
            <NumberInput width="80px" size='lg' defaultValue={1} min={1} border="none" m="auto" allowMouseWheel value={sliderValue} onChange={(valueString) => handleChange(parseInt(valueString))} >
              <NumberInputField color="white" border="none" _focus={{ border: 'none', boxShadow: 'none' }} />
              <NumberInputStepper>
                <NumberIncrementStepper
                  borderRadius="50% !important"
                  mb="2px"
                  children='+'
                  border="1px solid var(--toastify-text-color-light)"
                  onChange={(e) => {
                    setSliderValue(e.target.value)
                  }}
                />
                <NumberDecrementStepper
                  borderRadius="50% !important"
                  mt="2px"
                  children='-'
                  border="1px solid var(--toastify-text-color-light)"
                />
              </NumberInputStepper>
            </NumberInput>
        </Flex>
        <Flex w='100px' justifyContent="center" flexDirection="column">
          <Text mb="5px" textAlign="right" fontSize='md'>Deposit:</Text>
          <Text textAlign="right" fontSize='xl'>${numberWithCommas(sliderValue * 600)}</Text>
        </Flex>
      </Flex>
    </Box>
  )
}

function DepositForm(props) {
  stripe = useStripe()
  elements = useElements()

  return <PaymentElement />
}

export default function HeaderLinks(props) {
  const history = useHistory();
  const { setUser } = useAuth();
  let { user } = useAuth();
  const [userInfo, setUserInfo] = useState({});
  const [qrCode, setQrCode] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [curDepositAmount, setCurDepositAmount] = useState(600);
  const [depositAmount, setDepositAmount] = useState(0);
  const [clientSecret, setClientSecret] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [wallet, setWallet] = useState("");
  const { onCopy, hasCopied } = useClipboard(wallet);
  const { secondary } = props;
  let menuBg = useColorModeValue("white", "navy.800");
  const ethColor = useColorModeValue("gray.700", "white");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const handleLogout = async () => {
    await AuthApi.Logout(user);
    await setUser(null);
    localStorage.removeItem("user");
    return history.push("/auth/sign-in");
  };

  const handleDeposit = async () => {
    if (depositAmount == 0) {
      setIsLoading(true)

      var res = await PaymentApi.GetClientSecretKey({
        amount: curDepositAmount
      })

      setClientSecret(res.data.client_secret)
      setDepositAmount(curDepositAmount)
      setIsLoading(false)
    } else {
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true)
  
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: "http://localhost:3000/#/admin/default",
        },
        redirect: "if_required"
      });
  
      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        toast.error(result.error.message)
      } else {
        toast.success('Deposit was completed successfully! Once your deposit is confirmed, your balance will be charged.')

        await PaymentApi.AddPaymentHistory({
          amount: result.paymentIntent.amount / 100,
          type: 'stripe',
          id: result.paymentIntent.id
        })

        onClose()
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }

      setIsLoading(false)
    }
  }

  async function init() {
    var user = await UserApi.getOne();

    setIsLoading(false)
    setUserInfo(user.data);
    setWallet(user.data.wallet);

    setInterval(async () => {
      var user = await UserApi.getOne();

      setUserInfo(user.data);
    }, 5000);

    // later in code
    const qr = new EthereumQRPlugin();

    const qrCode = qr.toDataUrl({
      to: user.data.wallet,
      mode: "erc20__transfer",
    });

    qrCode.then(function (qrCodeDataUri) {
      setQrCode(qrCodeDataUri.dataURL);
    });
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Flex
        w={{ sm: "100%", md: "auto" }}
        alignItems="center"
        flexDirection="row"
        bg={menuBg}
        flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
        p="10px"
        borderRadius="30px"
        boxShadow={shadow}
      >
        {/* <SearchBar
          mb={secondary ? { base: "10px", md: "unset" } : "unset"}
          me='10px'
          borderRadius='30px'
        /> */}
        <Flex
          bg={ethBg}
          display="flex"
          borderRadius="30px"
          ms="auto"
          p="6px"
          onClick={(e) => {
            setDepositAmount(0)
            onOpen()
          }}
          align="center"
          cursor="pointer"
          me="6px"
        >
          <Flex
            align="center"
            justify="center"
            bg={ethBox}
            h="29px"
            w="29px"
            borderRadius="30px"
            me="7px"
          >
            <Icon color={ethColor} w="9px" h="14px" as={FaWallet} />
          </Flex>
          <Text
            w="max-content"
            color={ethColor}
            fontSize="sm"
            fontWeight="700"
            me="6px"
          >
            ${" "}
            {numberWithCommas(
              userInfo.balance ? userInfo.balance.toFixed(2) : "0.00"
            )}
          </Text>
        </Flex>

        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: "pointer" }}
              color="white"
              name=""
              bg="#11047A"
              size="sm"
              w="40px"
              h="40px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex flexDirection="column" p="10px">
              {/* <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                borderRadius='8px'
                px='14px'>
                <Text fontSize='sm'>Profile Settings</Text>
              </MenuItem> */}
              {/* <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                borderRadius='8px'
                px='14px'>
                <Text fontSize='sm'>Newsletter Settings</Text>
              </MenuItem> */}
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                color="red.400"
                borderRadius="8px"
                px="14px"
                onClick={handleLogout}
              >
                <Text fontSize="sm">Log out</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Deposit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading == true && depositAmount == 0 &&
              <Flex width="100%" m="auto">
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                  size='xl'
                  style={{margin: "auto"}}
                />
              </Flex>
            }
            {(isLoading == false || depositAmount != 0) &&
              <Tabs onChange={(index) => setTabIndex(index)}>
                <TabList>
                  <Tab>
                    <Icon as={FaCreditCard} />
                    &nbsp;Stripe&nbsp;
                    {'&'}&nbsp;
                    <Icon as={FaPaypal} />
                    &nbsp;Paypal
                  </Tab>
                  <Tab>
                    <Icon as={FaEthereum} />
                    &nbsp;USDT(ERC-20)
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    {depositAmount == 0 && <DepositAmountSelector setDepositAmount={setCurDepositAmount} />}
                    {depositAmount != 0 && 
                      <Elements stripe={stripePromise} options={{
                        clientSecret: clientSecret,
                        appearance: {
                          theme: 'night',
                          labels: 'floating',
                        },
                      }}>
                        <DepositForm />
                      </Elements>
                    }
                  </TabPanel>
                  <TabPanel>
                    <p>
                      You can send USDT(ERC-20) to the following wallet address to
                      deposit. And the deposit fee is 5.00 USDT. Ex. if you
                      deposit 100.00 USDT, then your balance will increase just
                      95.00 USDT.
                    </p>
                    <br />
                    <p style={{ display: "flex", alignItems: "start" }}>
                      <Icon as={FaExclamationCircle} mt="4px" mr="5px" />
                      IMPORTANT! YOU MUST SEND USDT(ERC-20) ONLY!
                    </p>
                    <VStack mt="20px">
                      <Center>
                        <Image src={qrCode} />
                      </Center>
                    </VStack>
                    <br />
                    <Flex mb={2}>
                      <Input
                        value={wallet}
                        readOnly={true}
                        color="white"
                        mr={2}
                      />
                      <Button onClick={onCopy} colorScheme="blue">
                        {hasCopied ? "Copied!" : "Copy"}
                      </Button>
                    </Flex>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            }
          </ModalBody>
          <ModalFooter>
            {tabIndex != 2 && (
              <Button colorScheme="green" mr="10px" onClick={handleDeposit} disabled={isLoading}>
                { isLoading == true && <Spinner
                  size='xs'
                /> }&nbsp;Deposit ${numberWithCommas(curDepositAmount)}
              </Button>
            )}
            <Button colorScheme="red" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
